import { Navbar,Nav, NavItem, Row, Col, Container,Button} from 'react-bootstrap';

import './LibraryMenu.css';

function LibraryMenu(){   

    return(
            <Container>
                <Row>

                </Row>
                {/* <ListGroup horizontal='True'>
                    <ListGroup.Item>This</ListGroup.Item>
                <ListGroup.Item>ListGroup</ListGroup.Item>
      <ListGroup.Item>renders</ListGroup.Item>
      <ListGroup.Item>horizontally!</ListGroup.Item>
    </ListGroup> */}
    <Row className='rowm' lg={4}>
       <Button href="#" className="ServiceButton" style={{backgroundColor : '#9F5151'}}>Service</Button>
       <Button href="#" className="ServiceButton" style={{backgroundColor : '#B62A2A'}}>Reservations</Button>
       <Button href="#" className="ServiceButton" style={{backgroundColor : '#D74D4D'}}>News</Button>
       <Button href="#" className="ServiceButton" style={{backgroundColor : '#FF4F4F'}}>Research</Button>
    </Row>

            </Container>
    )


};

export default LibraryMenu;
