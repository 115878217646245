import { Navbar,Nav, NavItem, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BrowserRouter, NavLink } from 'react-router-dom';
import './Navbar.css';

function Navibar() {

  return (
    <div className='Navibar'>
      <Navbar className="justify-content-between" bg="light" expand="lg" sticky="top">
        <Container fluid>
          <Row>
            <Col>
            <Navbar.Brand >
              <img className="logo" src="logo.png"></img>
            </Navbar.Brand>
            </Col>
          <Col> 
            <BrowserRouter>
              <Nav.Link className="navMenu"  to={"Catalog"} >Catalog</Nav.Link>
              <Nav.Link className="navMenu" to={"AboutUs"}>About Us</Nav.Link>
              <Nav.Link className="navMenu" to={"Events"}>Events</Nav.Link>  
            </BrowserRouter>
            </Col>
            <Col>
            <Form className="NavSearch">
            <Form.Control
              type="search"
              placeholder="Search"
              className="searchBar"
              aria-label="Search"
            />
            <Button variant="outline-success" className="searchButton">Search</Button>
          </Form>
          </Col>
          <Col>
            <BrowserRouter>
              <Nav.Link className="navMenu"  to={"Language"} >Language</Nav.Link>
              <Nav.Link className="navMenu" to={"Accessbility"}>Accessbility</Nav.Link>
              <Nav.Link className="navMenu" to={"Account"}>Account</Nav.Link>  
            </BrowserRouter>
            </Col>
            </Row>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navibar;
