import Navibar from './Components/Navibar';
import LibraryMenu from './Components/LibraryMenu';
import './App.css';

function App() {
  return (
    <div className="App">
      { <Navibar/> }
      {<LibraryMenu/>}
    </div>
  );
}

export default App;
